import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import GlobalStyles from "@mui/material/GlobalStyles";
import Footer from "../../components/website/Footer";
import { NavStrip } from "../../components/website/Navbar";
import { connect } from "react-redux";
import StarIcon from "@mui/icons-material/Star";

import { Box, Typography } from "@mui/material";
import BreadCrumb from "../../components/website/BreadCrumb";
import { COLORS, FONTS, IMAGES } from "../../constants";

const UserAgreement = (props) => {
  return (
    <React.Fragment>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <CssBaseline />
      <NavStrip />
      <Box
        style={{
          display: "flex",
          backgroundImage: `url(${IMAGES.breadcrumb})`,
          height: 300,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          className="breadcrumb-option container"
          style={{
            backgroundColor: "transparent",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              fontFamily: "PlayfairDisplay-Bold",
              fontSize: 40,
              color: COLORS.primary,
              textAlign: "center",
            }}
          >
            User Agreement
          </Typography>
          <img
            src={IMAGES.breadcrumb_img}
            style={{
              maxHeight: 300,
              position: "absolute",
              right: 0,
            }}
          />
        </Box>
      </Box>
      <Box
        className="container"
        sx={{
          mt: 15,
        }}
      >
        <Typography sx={[FONTS.title]}>
          Mobile Application End User License Agreement
        </Typography>
        <Typography sx={[FONTS.subtitle2, { mt: 2, fontSize: 18 }]}>
          Introduction
        </Typography>
        <Typography sx={[FONTS.subtitle, { mt: 2, fontSize: 16 }]}>
          This Mobile Application End User License Agreement ("Agreement") is a
          binding agreement between you ("End User" or "you") and Hair Scout LLC
          ("Company"). This Agreement governs your use of the Hair Scout App,
          (including all related documentation, the "Application"). The
          Application is licensed, not sold, to you.
        </Typography>

        <Typography sx={[FONTS.subtitle2, { mt: 2, fontSize: 18 }]}>
          License Grant
        </Typography>
        <Typography sx={[FONTS.subtitle, { fontSize: 16, mt: 2 }]}>
          Subject to the terms of this Agreement, Company grants you a limited,
          non-exclusive, and nontransferable license to:
        </Typography>

        <ul>
          <li>
            download, install, and use the Application for your personal,
            non-commercial use on a single mobile device owned or otherwise
            controlled by you ("Mobile Device") strictly in accordance with the
            Application's documentation; and{" "}
          </li>
          <li>
            access, stream, download, and use on such Mobile Device the Content
            and Services (as defined in 5) made available in or otherwise
            accessible through the Application, strictly in accordance with this
            Agreement and the Terms of Use applicable to such Content and
            Services as set forth in 5.
          </li>
        </ul>

        <Typography sx={[FONTS.subtitle2, { mt: 2, fontSize: 18 }]}>
          License Restrictions
        </Typography>
        <Typography sx={[FONTS.subtitle, { fontSize: 16, mt: 2 }]}>
          You shall not:
        </Typography>
        <ul>
          <li>
            copy the Application, except as expressly permitted by this license;
          </li>
          <li>
            modify, translate, adapt, or otherwise create derivative works or
            improvements, whether or not patentable, of the Application;
          </li>
          <li>
            reverse engineer, disassemble, decompile, decode, or otherwise
            attempt to derive or gain access to the source code of the
            Application or any part thereof;
          </li>
          <li>
            remove, delete, alter, or obscure any trademarks or any copyright,
            trademark, patent, or other intellectual property or proprietary
            rights notices from the Application, including any copy thereof;
          </li>
          <li>
            rent, lease, lend, sell, sublicense, assign, distribute, publish,
            transfer, or otherwise make available the Application, or any
            features or functionality of the Application, to any third party for
            any reason, including by making the Application available on a
            network where it is capable of being accessed by more than one
            device at any time;
          </li>
          <li>
            remove, disable, circumvent, or otherwise create or implement any
            workaround to any copy protection, rights management, or security
            features in or protecting the Application;
          </li>
        </ul>
        <Typography sx={[FONTS.subtitle2, { mt: 2, fontSize: 18 }]}>
          Reservation of Rights.
        </Typography>
        <Typography sx={[FONTS.subtitle, { fontSize: 16, mt: 2 }]}>
          You acknowledge and agree that the Application is provided under
          license, and not sold, to you. You do not acquire any ownership
          interest in the Application under this Agreement, or any other rights
          thereto other than to use the Application in accordance with the
          license granted, and subject to all terms, conditions, and
          restrictions, under this Agreement. Company reserves and shall retain
          its entire right, title, and interest in and to the Application,
          including all copyrights, trademarks, and other intellectual property
          rights therein or relating thereto, except as expressly granted to you
          in this Agreement.
        </Typography>
        <Typography sx={[FONTS.subtitle2, { mt: 2, fontSize: 18 }]}>
          Collection and Use of Your Information.
        </Typography>
        <Typography sx={[FONTS.subtitle, { fontSize: 16, mt: 2 }]}>
          You acknowledge that when you download, install, or use the
          Application, Company may use automatic means (including, for example,
          cookies and web beacons) to collect information about your Mobile
          Device and about your use of the Application. You also may be required
          to provide certain information about yourself as a condition to
          downloading, installing, or using the Application or certain of its
          features or functionality, and the Application may provide you with
          opportunities to share information about yourself with others. All
          information we collect through or in connection with this Application
          is subject to our Privacy Policy. By downloading, installing, using,
          and providing information to or through this Application, you consent
          to all actions taken by us with respect to your information in
          compliance with the Privacy Policy.
        </Typography>
        <Typography sx={[FONTS.subtitle2, { mt: 2, fontSize: 18 }]}>
          Content and Services
        </Typography>
        <Typography sx={[FONTS.subtitle, { fontSize: 16, mt: 2 }]}>
          The Application may provide you with access to Company's website (the
          "Website") and products and services accessible thereon, and certain
          features, functionality, and content accessible on or through the
          Application may be hosted on the Website (collectively, "Content and
          Services"). Your access to and use of such Content and Services are
          governed by Website's Terms of Use and Privacy, which are incorporated
          herein by this reference. Your access to and use of such Content and
          Services may require you to acknowledge your acceptance of such Terms
          of Use and Privacy Policy and/or to register with the Website, and
          your failure to do so may restrict you from accessing or using certain
          of the Application's features and functionality. Any violation of such
          Terms of Use will also be deemed a violation of this Agreement.
        </Typography>
        <Typography sx={[FONTS.subtitle2, { mt: 2, fontSize: 18 }]}>
          Geographic Restrictions
        </Typography>
        <Typography sx={[FONTS.subtitle, { fontSize: 16, mt: 2 }]}>
          The Content and Services are based in the state of Michigan in the
          United States and provided for access and use only by persons located
          in the United States. You acknowledge that you may not be able to
          access all or some of the Content and Services outside of the United
          States and that access thereto may not be legal by certain persons or
          in certain countries. If you access the Content and Services from
          outside the United States, you are responsible for compliance with
          local laws.
        </Typography>
        <Typography sx={[FONTS.subtitle2, { mt: 2, fontSize: 18 }]}>
          Updates
        </Typography>
        <Typography sx={[FONTS.subtitle, { fontSize: 16, mt: 2 }]}>
          Company may from time to time in its sole discretion develop and
          provide Application updates, which may include upgrades, bug fixes,
          patches, other error corrections, and/or new features (collectively,
          including related documentation, "Updates"). Updates may also modify
          or delete in their entirety certain features and functionality. You
          agree that Company has no obligation to provide any Updates or to
          continue to provide or enable any particular features or
          functionality. Based on your Mobile Device settings, when your Mobile
          Device is connected to the internet either:
        </Typography>

        <ul>
          <li>
            the Application will automatically download and install all
            available Updates; or
          </li>
          <li>
            you may receive notice of or be prompted to download and install
            available Updates.
          </li>
        </ul>
        <Typography sx={[FONTS.subtitle, { fontSize: 16, mt: 2 }]}>
          You shall promptly download and install all Updates and acknowledge
          and agree that the Application or portions thereof may not properly
          operate should you fail to do so. You further agree that all Updates
          will be deemed part of the Application and be subject to all terms and
          conditions of this Agreement.
        </Typography>
        <Typography sx={[FONTS.subtitle2, { mt: 2, fontSize: 18 }]}>
          Third-Party Materials
        </Typography>
        <Typography sx={[FONTS.subtitle, { fontSize: 16, mt: 2 }]}>
          The Application may display, include, or make available third-party
          content (including data, information, applications, and other
          products, services, and/or materials) or provide links to third-party
          websites or services, including through third-party advertising
          ("Third-Party Materials"). You acknowledge and agree that Company is
          not responsible for Third-Party Materials, including their accuracy,
          completeness, timeliness, validity, copyright compliance, legality,
          decency, quality, or any other aspect thereof. Company does not assume
          and will not have any liability or responsibility to you or any other
          person or entity for any Third-Party Materials. Third-Party Materials
          and links thereto are provided solely as a convenience to you, and you
          access and use them entirely at your own risk and subject to such
          third parties' terms and conditions.
        </Typography>
        <Typography sx={[FONTS.subtitle2, { mt: 2, fontSize: 18 }]}>
          Term and Termination
        </Typography>
        <ul>
          <li>
            The term of Agreement commences when you download/install the
            Application and will continue in effect until terminated by you or
            Company as set forth in this 9
          </li>
          <li>
            You may terminate this Agreement by deleting the Application and all
            copies thereof from your Mobile Device.
          </li>
          <li>
            Company may terminate this Agreement at any time without notice if
            it ceases to support the Application, which Company may do in its
            sole discretion. In addition, this Agreement will terminate
            immediately and automatically without any notice if you violate any
            of the terms and conditions of this Agreement.
          </li>
          <li>
            Upon termination:
            <ol>
              <li>
                all rights granted to you under this Agreement will also
                terminate; and
              </li>
              <li>
                you must cease all use of the Application and delete all copies
                of the Application from your Mobile Device and account.
              </li>
            </ol>
          </li>
          <li>
            Termination will not limit any of Company's rights or remedies at
            law or in equity.
          </li>
        </ul>
        <Typography sx={[FONTS.subtitle2, { mt: 2, fontSize: 18 }]}>
          Disclaimer of Warranties
        </Typography>
        <Typography sx={[FONTS.subtitle, { fontSize: 16, mt: 2 }]}>
          THE APPLICATION IS PROVIDED TO END USER "AS IS" AND WITH ALL FAULTS
          AND DEFECTS WITHOUT WARRANTY OF ANY KIND. TO THE MAXIMUM EXTENT
          PERMITTED UNDER APPLICABLE LAW, COMPANY, ON ITS OWN BEHALF AND ON
          BEHALF OF ITS AFFILIATES AND ITS AND THEIR RESPECTIVE LICENSORS AND
          SERVICE PROVIDERS, EXPRESSLY DISCLAIMS ALL WARRANTIES, WHETHER
          EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE, WITH RESPECT TO THE
          APPLICATION, INCLUDING ALL IMPLIED WARRANTIES OF MERCHANTABILITY,
          FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT, AND
          WARRANTIES THAT MAY ARISE OUT OF COURSE OF DEALING, COURSE OF
          PERFORMANCE, USAGE, OR TRADE PRACTICE. WITHOUT LIMITATION TO THE
          FOREGOING, COMPANY PROVIDES NO WARRANTY OR UNDERTAKING, AND MAKES NO
          REPRESENTATION OF ANY KIND THAT THE APPLICATION WILL MEET YOUR
          REQUIREMENTS, ACHIEVE ANY INTENDED RESULTS, BE COMPATIBLE, OR WORK
          WITH ANY OTHER SOFTWARE, APPLICATIONS, SYSTEMS, OR SERVICES, OPERATE
          WITHOUT INTERRUPTION, MEET ANY PERFORMANCE OR RELIABILITY STANDARDS,
          OR BE ERROR-FREE, OR THAT ANY ERRORS OR DEFECTS CAN OR WILL BE
          CORRECTED.
          <br /> SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF OR LIMITATIONS
          ON IMPLIED WARRANTIES OR THE LIMITATIONS ON THE APPLICABLE STATUTORY
          RIGHTS OF A CONSUMER, SO SOME OR ALL OF THE ABOVE EXCLUSIONS AND
          LIMITATIONS MAY NOT APPLY TO YOU.
        </Typography>
        <Typography sx={[FONTS.subtitle2, { mt: 2, fontSize: 18 }]}>
          Limitation of Liability
        </Typography>
        <Typography sx={[FONTS.subtitle, { fontSize: 16, mt: 2 }]}>
          TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL
          COMPANY OR ITS AFFILIATES, OR ANY OF ITS OR THEIR RESPECTIVE LICENSORS
          OR SERVICE PROVIDERS, HAVE ANY LIABILITY ARISING FROM OR RELATED TO
          YOUR USE OF OR INABILITY TO USE THE APPLICATION OR THE CONTENT AND
          SERVICES FOR:
        </Typography>
        <ul>
          <li>
            PERSONAL INJURY, PROPERTY DAMAGE, LOST PROFITS, COST OF SUBSTITUTE
            GOODS OR SERVICES, LOSS OF DATA, LOSS OF GOODWILL, BUSINESS
            INTERRUPTION, COMPUTER FAILURE OR MALFUNCTION, OR ANY OTHER
            CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL, OR PUNITIVE
            DAMAGES.
          </li>
          <li>
            DIRECT DAMAGES IN AMOUNTS THAT IN THE AGGREGATE EXCEED THE AMOUNT
            ACTUALLY PAID BY YOU FOR THE APPLICATION.{" "}
          </li>
        </ul>
        <Typography sx={[FONTS.subtitle, { fontSize: 16, mt: 2 }]}>
          EXCEED THE AMOUNT ACTUALLY PAID BY YOU FOR THE APPLICATION. THE
          FOREGOING LIMITATIONS WILL APPLY WHETHER SUCH DAMAGES ARISE OUT OF
          BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), OR OTHERWISE AND
          REGARDLESS OF WHETHER SUCH DAMAGES WERE FORESEEABLE OR COMPANY WAS
          ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. SOME JURISDICTIONS DO NOT
          ALLOW CERTAIN LIMITATIONS OF LIABILITY SO SOME OR ALL OF THE ABOVE
          LIMITATIONS OF LIABILITY MAY NOT APPLY TO YOU.
        </Typography>

        <Typography sx={[FONTS.subtitle2, { mt: 2, fontSize: 18 }]}>
          Indemnification
        </Typography>
        <Typography sx={[FONTS.subtitle, { fontSize: 16, mt: 2 }]}>
          You agree to indemnify, defend, and hold harmless Company and its
          officers, directors, employees, agents, affiliates, successors, and
          assigns from and against any and all losses, damages, liabilities,
          deficiencies, claims, actions, judgments, settlements, interest,
          awards, penalties, fines, costs, or expenses of whatever kind,
          including attorneys' fees, arising from or relating to your use or
          misuse of the Application or your breach of this Agreement, including
          but not limited to the content you submit or make available through
          this Application.
        </Typography>
        <Typography sx={[FONTS.subtitle2, { mt: 2, fontSize: 18 }]}>
          Export Regulation
        </Typography>
        <Typography sx={[FONTS.subtitle, { fontSize: 16, mt: 2 }]}>
          The Application may be subject to US export control laws, including
          the Export Control Reform Act and its associated regulations. You
          shall not, directly or indirectly, export, re-export, or release the
          Application to, or make the Application accessible from, any
          jurisdiction or country to which export, re-export, or release is
          prohibited by law, rule, or regulation. You shall comply with all
          applicable federal laws, regulations, and rules, and complete all
          required undertakings (including obtaining any necessary export
          license or other governmental approval), prior to exporting,
          re-exporting, releasing, or otherwise making the Application available
          outside the US.
        </Typography>
        <Typography sx={[FONTS.subtitle2, { mt: 2, fontSize: 18 }]}>
          Severability
        </Typography>
        <Typography sx={[FONTS.subtitle, { fontSize: 16, mt: 2 }]}>
          If any provision of this Agreement is illegal or unenforceable under
          applicable law, the remainder of the provision will be amended to
          achieve as closely as possible the effect of the original term and all
          other provisions of this Agreement will continue in full force and
          effect.
        </Typography>
        <Typography sx={[FONTS.subtitle2, { mt: 2, fontSize: 18 }]}>
          Governing Law
        </Typography>
        <Typography sx={[FONTS.subtitle, { fontSize: 16, mt: 2 }]}>
          This Agreement is governed by and construed in accordance with the
          internal laws of the State of Michigan without giving effect to any
          choice or conflict of law provision or rule. Any legal suit, action,
          or proceeding arising out of or related to this Agreement or the
          Application shall be instituted exclusively in the federal courts of
          the United States or the courts of the State of Michigan. You waive
          any and all objections to the exercise of jurisdiction over you by
          such courts and to venue in such courts.
        </Typography>
        <Typography sx={[FONTS.subtitle2, { mt: 2, fontSize: 18 }]}>
          Limitation of Time to File Claims.
        </Typography>
        <Typography sx={[FONTS.subtitle, { fontSize: 16, mt: 2 }]}>
          ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING
          TO THIS AGREEMENT OR THE APPLICATION MUST BE COMMENCED WITHIN ONE (1)
          YEAR AFTER THE CAUSE OF ACTION ACCRUES OTHERWISE SUCH CAUSE OF ACTION
          OR CLAIM IS PERMANENTLY BARRED.
        </Typography>
        <Typography sx={[FONTS.subtitle2, { mt: 2, fontSize: 18 }]}>
          Entire Agreement
        </Typography>
        <Typography sx={[FONTS.subtitle, { fontSize: 16, mt: 2 }]}>
          This Agreement and our Privacy Policies constitute the entire
          agreement between you and Company with respect to the Application and
          supersede all prior or contemporaneous understandings and agreements,
          whether written or oral, with respect to the Application.
        </Typography>
        <Typography sx={[FONTS.subtitle2, { mt: 2, fontSize: 18 }]}>
          Waiver
        </Typography>
        <Typography sx={[FONTS.subtitle, { fontSize: 16, mt: 2 }]}>
          No failure to exercise, and no delay in exercising, on the part of
          either party, any right or any power hereunder shall operate as a
          waiver thereof, nor shall any single or partial exercise of any right
          or power hereunder preclude further exercise of that or any other
          right hereunder. In the event of a conflict between this Agreement and
          any applicable purchase or other terms, the terms of this Agreement
          shall govern.
        </Typography>
      </Box>

      <Footer />
    </React.Fragment>
  );
};
const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(UserAgreement);
