import {
  Container,
  Grid,
  Card, 
  Box,
  FormControl,
  Button,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Stack,
  CardMedia,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import Storefront from "@mui/icons-material/Storefront";
import ShoppingBasket from "@mui/icons-material/ShoppingBasket";
import Rating from "@mui/material/Rating";
import Page from "../../../components/Page";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { BASE_URL } from "../../../apis";
import { handleGetApi, handlePostApi } from "../../../services";
import { CUSTOMER_PRODUCTS, SHOPPING_CART } from "../../../apis";
import { toast } from "react-toastify";
import { COLORS, FONTS } from "../../../constants/theme";
import LoadingModal from "../../../components/modals/LoadingModal";
import { NavStrip } from "../../../components/website/Navbar";
import { useDispatch, useSelector } from "react-redux";
import { addItem, updateItem } from "../../../redux/Slice/CartSlice";

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

function DashboardList() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.cart.items);
  const parentdid = location?.state?.id;
  const token = localStorage.getItem("token");
  const [allProducts, setAllProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modifiedProducts, setModifiedProducts] = useState([]);
  const [orderLimitQuantities, setOrderLimitQuantities] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');


  useEffect(() => {
    getproductss();
  }, []);

  const getproductss = async () => {
    // showonHomepage =true is added  if at this endpoint categroy foun show data other wise return []
    let url = `${BASE_URL.baseUrl}${CUSTOMER_PRODUCTS.getCustomerProducts}PageSize=100&Page=1&CategoryId=${parentdid}&ShowOnHomePage=true`;
    try {
      const response = await handleGetApi(url, token);

      setAllProducts(response?.data?.products);
      setFilteredProducts(response?.data?.products);
      const initialQuantities = {};
      response?.data?.products.forEach(product => {
          initialQuantities[product.id] = 1;
      });
      setOrderLimitQuantities(initialQuantities);
      setLoading(false);
    } catch (err) {
      toast.error("Something Went Wrong");
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    if (query.trim() === '') {
        setFilteredProducts(allProducts);
    } else {
        const filtered = allProducts.filter(product => 
            product.name.toLowerCase().includes(query)
        );
        setFilteredProducts(filtered);
    }
  };

  const handleIncreaseQuantity = (productId) => {
    setOrderLimitQuantities(prevQuantities => ({
        ...prevQuantities,
        [productId]: prevQuantities[productId] + 1
    }));
  };

  const handleDecreaseQuantity = (productId) => {
    setOrderLimitQuantities(prevQuantities => ({
        ...prevQuantities,
        [productId]: Math.max(1, prevQuantities[productId] - 1)
    }));
  };

  const handleChange = (e, productId) => {
    e.stopPropagation(); // Prevent navigation on dropdown change
    const { name, value } = e.target;
    const modifiedProduct = allProducts.find(product => product.id === productId);
    const updatedProduct = {
        ...modifiedProduct,
        [name]: value
    };
    setModifiedProducts(prevState => ({
        ...prevState,
        [productId]: {
            ...prevState[productId],
            ...updatedProduct 
        }
    }));
  };
  const handleAddToCart = (product, index) => {
    const data = modifiedProducts[product.id];
    if (!data || Object.keys(data).length === 0) {
      toast.error("Please select color and length");
    } else if (!data.length || !data.color) {
      let errorMessage = "";
      if (!data.length) {
        errorMessage += "length ";
      }
      if (!data.color) {
        errorMessage += "color ";
      }
      toast.error("Error: Please select " + errorMessage + "to continue.");
    } else if (
      orderLimitQuantities[product.id] > data.length.stock ||
      orderLimitQuantities[product.id] > data.color.stock
    ) {
      toast.error("Error: Quantity exceeds stock. Please decrease quantity.");
    } else {
      const cartItem = {
        ...product,
        quantity: orderLimitQuantities[product.id],
        shopping_cart_type: "ShoppingCart",
        product_attributes: [
          {
            id: data?.length?.length_attribute_id,
            value: data?.length?.length_value_id,
          },
          {
            id: data?.color?.color_attribute_id,
            value: data?.color?.color_value_id,
          },
        ],
      };
  
      // Generate a unique key for the combination of product ID and attributes
      const itemKey = `${product.id}-${data.length.length_value_id}-${data.color.color_value_id}`;
  
      const existingItem = cartItems.find(
        (item) => `${item.id}-${item.product_attributes[0].value}-${item.product_attributes[1].value}` === itemKey
      );
  
      try {
        if (existingItem) {
          dispatch(updateItem(cartItem));
          toast.success("Item Updated In Cart Successfully");
        } else {
          dispatch(addItem(cartItem));
          toast.success("Item Added To Cart Successfully");
        }
      } catch (err) {
        toast.error("Something Went Wrong");
      } finally {
        setLoading(false);
      }
    }
  };
  
  const handleClick = (item) => {
    navigate("/product-details", { state: item });
  };

  const productsToDisplay = searchQuery ? filteredProducts : allProducts;

  return (
    <>
      <NavStrip/>
      <RootStyle>
        <Container maxWidth="lg" sx={{ mt: 4 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mb: 5,
            }}
          >
            <InputBase
              placeholder="Search product"
              sx={{
                border: "1px solid rgba(0, 0, 0, 0.12)",
                borderRadius: "10px",
                px: 2,
                width: "100%",
                display: "flex",
                alignItems: "center",
              }}
              value={searchQuery}
              onChange={handleSearch}
              endAdornment={
                <SearchIcon sx={{ color: "text.disabled", mr: 1 }} />
              }
            />
          </Box>
          <Grid container spacing={3}>
            {loading ? (
              <>
                 <LoadingModal open={loading} />
              </>
            ) : (
              <>
                {productsToDisplay && productsToDisplay.length > 0 ? (
                  productsToDisplay.map((product ,index) => (
                    <Grid item xs={12} sm={6} key={product.id}>
                      <Card 
                        sx={{ maxWidth: 650, cursor: "pointer" }} 
                        onClick={() => handleClick(product)}
                      >
                        <Box
                          sx={{
                            flex: "0 0 auto",
                            ml: 1,
                            mt: 2,
                            position: "relative",
                            display: "inline-block",
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: "#4d2d25",
                              width: "40px",
                              height: "40px",
                              borderRadius: "50%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Storefront style={{ color: "white" }} />
                          </div>
                        </Box>
                        <CardMedia
                          component="img"
                          image={
                            product.images && product.images.length > 0
                              ? product.images[0].src
                              : ""
                          }
                          sx={{
                            width: "100%",
                            maxHeight: 400,
                            objectFit: "contain",
                            borderRadius: "10px",
                            marginTop: 2,
                            border: "4px solid white",
                            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                          }}
                        />
                        <Box sx={{ flex: "1 1 auto", ml: 1, mt: 2 }}>
                          <Typography variant="h6">{product.name}</Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            ml: 2,
                            mt: 1,
                          }}
                        >
                          {/* <Typography variant="body2" sx={{ fontSize: 14, mr:0.5, color: "text.secondary" }}>
                            {product.rating_average}
                          </Typography> */}
                          <Rating
                            name={`rating-${product.id}`}
                            value={product.rating_average}
                            readOnly
                            precision={0.5}
                            sx={{ fontSize: 17 }}
                          />
                          <Typography variant="body2" sx={{ fontSize: 14 }}>
                            ({product.no_of_reviews})
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            mt: 2,
                            ml: 2,
                          }}
                        >
                          <Typography
                            variant="body1"
                            sx={{
                              fontWeight: "bold",
                              fontSize: 18,
                              fontFamily: "Roboto, sans-serif",
                              letterSpacing: "0.5px",
                              color: "text.primary",
                              mr: 1,
                            }}
                          >{`$${product.price}`}</Typography>
                          {/* <Typography
                            variant="body1"
                            sx={{
                              color: "text.secondary",
                              textDecoration: "line-through",
                              fontSize: 13,
                            }}
                          >{`$${product.price}`}</Typography> */}
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            mt: 2,
                            flexDirection: { xs: "column", md: "row" },
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: { xs: 2, md: 0 },
                              marginRight: { md: 2 },
                            }}
                          >
                            <InputLabel
                              sx={{
                                minWidth: 100,
                                ml: 1.5,
                                marginRight: { xs: 0, md: 1 },
                              }}
                            >
                              Length
                            </InputLabel>
                            <FormControl 
                              sx={{ minWidth: 150 }}
                              onClick={(e) => e.stopPropagation()} // Prevent navigation on dropdown click
                            >
                              <Select
                                sx={{ height: 35 }}
                                name="length"
                                onChange={(e) => handleChange(e, product.id)}
                              >
                                {product.attributes.map((value, index) => (
                                  <MenuItem
                                    key={index}
                                    value={value}
                                  >
                                    {value.length_inches}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: { xs: 2, md: 0 },
                              marginRight: { md: 2 },
                            }}
                          >
                            <InputLabel
                              sx={{
                                minWidth: 100,
                                ml: 1.5,
                                marginRight: { xs: 0, md: 1 },
                              }}
                            >
                              Color
                            </InputLabel>
                            <FormControl 
                              sx={{ minWidth: 150 }}
                              onClick={(e) => e.stopPropagation()} // Prevent navigation on dropdown click
                            >
                              <Select
                                sx={{ height: 35 }}
                                name="color"
                                onChange={(e) => handleChange(e, product.id)}
                              >
                                {product.attributes.map((value, index) => (
                                  <MenuItem key={index} value={value}>
                                    {value.color}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Box>
                        </Box>
                        <Grid
                          item
                          xs={4}
                          md={4}
                          lg={4}
                          sx={{
                            mt: 2,
                      // justifyContent: "center",
                            alignItems: "center",
                            display: "flex",
                      paddingLeft:2
                          }}
                        >
                          <Typography
                            sx={{
                              fontFamily: FONTS.subtitle2,
                              color: COLORS.primary,
                              fontSize: 16,
                            }}
                          >
                            Quantity
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={8}
                          md={8}
                          lg={8}
                          sx={{
                            mt: 2,
                      paddingLeft:2
                          }}
                        >
                          <Stack
                            direction={{ xs: "row", md: "row", lg: "row" }}
                            sx={{ alignItems: "center" }}
                          >
                            <Button
                              variant="contained"
                              sx={{
                                my: 0.3,
                                borderRadius: 0,
                                height: 35,
                                width: 35,
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDecreaseQuantity(product.id);
                              }}
                            >
                              -
                            </Button>
                            <Typography
                              sx={{
                                fontFamily: FONTS.subtitle2,
                                textAlign: "center",
                                p: 0.7,
                                px: 3,
                                border: 1,
                                borderColor: COLORS.border,
                                height: 35,
                              }}
                            >
                              {orderLimitQuantities[product.id]}
                            </Typography>
                            <Button
                              variant="contained"
                              sx={{
                                my: 0.3,
                                borderRadius: 0,
                                height: 35,
                                width: 35,
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleIncreaseQuantity(product.id);
                              }}
                            >
                              +
                            </Button>
                          </Stack>
                        </Grid>
                        <Box sx={{ mx: 3, mt: "auto" }}>
                          <Button
                            variant="contained"
                            sx={{ width: "100%", mb: 2, height: 50, mt: 2 }}
                            size="large"
                            endIcon={<ShoppingBasket sx={{ fontSize: 24 }} />}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleAddToCart(product, index);
                            }}
                          >
                            Add to Cart
                          </Button>
                        </Box>
                      </Card>
                    </Grid>
                  ))
                ) : (
                  <Typography variant="body1" style={{ width: "100%", textAlign: "center", fontSize: "30px" }}>
                    No data found
                  </Typography>
                )}
              </>
            )}
          </Grid>
        </Container>
      </RootStyle>
    </>
  );
}

export default DashboardList;
