import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import GlobalStyles from "@mui/material/GlobalStyles";
import Container from "@mui/material/Container";
import { Avatar, Box, Button, Card, Paper, Stack } from "@mui/material";
import IMAGES from "../../constants/images";
import { COLORS, FONTS } from "../../constants/theme";
import BubbleChartIcon from "@mui/icons-material/BubbleChart";
import FavoriteIcon from "@mui/icons-material/Favorite";
import EnergySavingsLeafIcon from "@mui/icons-material/EnergySavingsLeaf";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { connect } from "react-redux";
import { NavStrip } from "../../components/website/Navbar";
import Footer from "../../components/website/Footer";
import ImageSlider from "../../components/website/Slider";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { makeStyles } from "@mui/styles";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import { BASE_URL } from "../../apis";
import { handleGetApi } from "../../services";
import { toast } from "react-toastify";
import LoadingModal from "../../components/modals/LoadingModal";

const useStyles = makeStyles({
  button: {
    backgroundColor: COLORS.secondary,
    color: COLORS.primary,
    "&:hover": {
      backgroundColor: COLORS.primary,
      color: "#fff",
    },
  },
});
const Home = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [catogeries, setCatogeries] = useState([]);

  useEffect(() => {
    getcategories();
  }, []);
  const getcategories = async () => {
    setLoading(true)
    let url = `${BASE_URL.baseUrl}categories?Limit=100&Page=1&PublishedStatus=true&ParentCategoryId=0`;
    try {

      const response = await handleGetApi(url, token);
      setCatogeries(response.data.categories);
      setLoading(false);
    } catch (err) {
      toast.error("Something Went Wrong");
      setLoading(false)
    }
  };


  const handleClickProducts = () => {
    navigate("/categories-list", { state: catogeries });
  };
  const handleClick = (item) => {
    navigate("/sub-categories-list", { state: item });
  };
  const handleClickAllProducts = () => {
    navigate("/all-products", { state: catogeries });
  };


  const classes = useStyles();
  return (
    <React.Fragment>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <CssBaseline />
      <NavStrip />
      <ImageSlider />
      {/* Hero unit */}
      {/* <Slider /> */}
      {/* <Box
        //style={styles.paperContainer}
        sx={{
          backgroundImage: `url(${IMAGES.slide1})`,
          height: 780,
          backgroundSize: "cover",
          display: "flex",
          justifyContent: "center",
          repeat: "no-repeat",
          flexDirection: "column",
        }}
      >
        <Typography
          sx={{
            fontFamily: "PlayFairDisplay-Bold",
            color: COLORS.white,
            pl: 10,
            fontSize: 50,
          }}
        >
          Connecting Hair Brands <br />& Shoppers in One Place.
        </Typography>
        <Typography
          sx={{
            fontFamily: "PTSans-Regular",
            color: COLORS.other,
            pl: 10,
            pt: 2,
            fontSize: 18,
            lineHeight: 2,
          }}
        >
          Get yourself and amazing deal on something beautiful, voluminous wigs
          that do <br /> not even dare to disappoint. Look through now and see
          what you can snag
        </Typography>
        <Button
          variant="contained" 
          sx={{
            alignSelf: "flex-start",
            ml: 10,
            mt: 4,
            borderRadius: 0,
            width: 150,
            height: 50, 
          }}
        >   
          SHOP NOW
        </Button>
      </Box> */}
      {/* <Container
        disableGutters
        maxWidth="lg"
        sx={{
          mt: 5,
          alignSelf: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Grid container spacing={2} sx={{ alignSelf: "center" }}>
          <Grid item xs={12} sm={12} md={4}>
            <Stack
              direction={{ xs: "row", sm: "row" }}
              sx={{
                alignItems: "center",
                backgroundColor: "#f5f5f5",
                p: 2,
              }}
            >
              <CableOutlinedIcon
                sx={{ fontSize: 55, color: COLORS.black, mr: 3 }}
              />

              <Typography
                style={{
                  fontFamily: "PTSans-Regular",
                  color: COLORS.grey,
                  fontSize: 15,
                }}
              >
                <Typography
                  className=""
                  variant="h5"
                  style={{
                    fontFamily: "PlayfairDisplay-Bold",
                    color: COLORS.black,
                    fontSize: 20,
                  }}
                >
                  Access
                </Typography>
                Connecting shoppers with their perfect brands, and brands with
                their perfect customers
              </Typography>
            </Stack>
          </Grid>
          <Grid item sx={12} sm={12} md={4}>
            <Stack
              direction={{ xs: "row", sm: "row" }}
              sx={{
                alignItems: "center",
                backgroundColor: "#f5f5f5",
                p: 2,
              }}
            >
              <GppGoodOutlinedIcon
                sx={{ fontSize: 55, color: COLORS.black, mr: 3 }}
              />
              <Typography
                style={{
                  fontFamily: "PTSans-Regular",
                  color: COLORS.grey,
                  fontSize: 15,
                }}
              >
                <Typography
                  variant="h5"
                  style={{
                    fontFamily: "PlayfairDisplay-Bold",
                    color: COLORS.black,
                    fontSize: 20,
                  }}
                >
                  Trust
                </Typography>
                We protect shoppers and brands from fraud so that you can shop
                and sell with confidence
              </Typography>
            </Stack>
          </Grid>
          <Grid item sx={12} sm={12} md={4}>
            <Stack
              direction={{ xs: "row", sm: "row" }}
              sx={{
                alignItems: "center",
                backgroundColor: "#f5f5f5",
                p: 2,
              }}
            >
              <ShoppingCartOutlinedIcon
                sx={{ fontSize: 55, color: COLORS.black, mr: 3 }}
              />
              <Typography
                style={{
                  fontFamily: "PTSans-Regular",
                  color: COLORS.grey,
                  fontSize: 15,
                }}
              >
                <Typography
                  variant="h5"
                  style={{
                    fontFamily: "PlayfairDisplay-Bold",
                    color: COLORS.black,
                    fontSize: 20,
                  }}
                >
                  Ease
                </Typography>
                Life should be easy. We make the shopping and selling process
                simple and hassle-free for our users
              </Typography>
            </Stack>
          </Grid>
          <Grid item sx={12} sm={12} md={3}>
            <Stack
              direction={{ xs: "row", sm: "row" }}
              sx={{
                alignItems: "center",
                backgroundColor: "#f5f5f5",
                padding: 2,
                borderRadius: 2,
              }}
            >
              <PeopleAltOutlinedIcon
                sx={{ fontSize: 55, color: COLORS.black, mr: 2 }}
              />
              <Typography
                style={{
                  fontFamily: "PTSans-Regular",
                  color: COLORS.grey,
                  fontSize: 14,
                }}
              >
                <Typography
                  variant="h5"
                  style={{
                    fontFamily: "PTSans-Bold",
                    color: COLORS.black,
                    fontSize: 16,
                  }}
                >
                  Chat Facility
                </Typography>
                Chat directly with Scouts, and get the latest scoop on trends
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Container> */}
      {/* <Container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: 10,
        }}
      >
        <Typography
          variant="h3"
          gutterBottom
          sx={{ fontFamily: "PlayfairDisplay-Bold" }}
        >
          Shop By Categories
        </Typography>
      </Container> */}
      <Container
        disableGutters
        maxWidth="lg"
        sx={{
          mt: 4,
          alignSelf: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {/* <Grid
          container
          spacing={2}
          sx={{ alignSelf: "center", justifyContent: "center" }}
        >
          {props.common.categories?.map((category, index) => (
            <Grid item xs={12} sm={12} md={2} index={index}>
              <Card
                style={{
                  ...SectionStyle,
                  backgroundImage: `url(${category.image.src})`,
                }}
                // onClick={() => navigate("/product-sub-categories")}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: COLORS.white,
                    fontFamily: "PlayfairDisplay-Bold",
                    textAlign: "left",
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    backgroundColor: "rgba(0,0,0,0.5)",
                    padding: 1,
                    width: "100%",
                    height: 50,
                  }}
                >
                  {category?.name}
                </Typography>
              </Card>
            </Grid>
          ))} */}
        <Container
          sx={{
            marginTop: 3,
          }}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Stack
            direction={{ xs: "column", sm: "row" }}
            width={{ xs: "200px", sm: "100%" }}
            sx={{
              justifyContent: "space-between",
              alignItems: "space-between",
            }}
          >
            <Box>
              <Typography
                variant="h3"
                gutterBottom
                sx={{ fontFamily: "PlayfairDisplay-Bold" }}
              >
                Shop By Category
              </Typography>
            </Box>
            {catogeries.length > 0 && (
              <Button
                variant="contained"
                sx={{ borderRadius: 0 }}
                onClick={handleClickAllProducts}
              >
                <Typography variant="p" sx={{ fontFamily: "PTSans-Bold" }}>
                  See All Products
                </Typography>
                <ArrowRightAltIcon />
              </Button>
            )}

          </Stack>
        </Container>
      </Container>
      {/* <Container sx={{ mt: 4 }}>
          <Carousel
            show={4.5}
            slide={4}
            swiping={true}
            rightArrow={true}
            leftArrow={true}
          >
            {props.common?.categories?.length > 0 &&
              props.common.categories?.map((category, index) => (
                <Box
                  sx={{
                    ...SectionStyle,
                    "&:hover": {
                      border: 5,
                      borderColor: COLORS.secondary,
                    },
                    backgroundImage: `url(${category.image.src})`,
                    backgroundRepeat: "no-repeat",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "PTSans-Bold",
                      color: COLORS.white,
                      position: "absolute",
                      bottom: 8,
                      height: 50,
                      width: 240,
                      alignSelf: "flex-start",
                      backgroundColor: "rgba(0,0,0,0.5)",
                      padding: 1.5,
                      fontSize: 18,
                    }}
                  >
                    {category?.name}
                  </Typography>
                </Box>
              ))}
          </Carousel>
        </Container> */}
      {/* <Grid item sx={12} sm={12} md={3}>
            <Stack
              direction={{ xs: "row", sm: "row" }}
              sx={{
                alignItems: "center",
                backgroundColor: "#f5f5f5",
                padding: 2,
                borderRadius: 2,
              }}
            >
              <PeopleAltOutlinedIcon
                sx={{ fontSize: 55, color: COLORS.black, mr: 2 }}
              />
              <Typography
                style={{
                  fontFamily: "PTSans-Regular",
                  color: COLORS.grey,
                  fontSize: 14,
                }}
              >
                <Typography
                  variant="h5"
                  style={{
                    fontFamily: "PTSans-Bold",
                    color: COLORS.black,
                    fontSize: 16,
                  }}
                >
                  Chat Facility
                </Typography>
                Chat directly with Scouts, and get the latest scoop on trends
              </Typography>
            </Stack>
          </Grid> */}
      {/* </Grid> */}
      <Container sx={{ marginTop: 5 }}>
        {loading ? (
          <LoadingModal open={loading} />
        ) : (
          <Grid container spacing={2} sx={{ justifyContent: "center" }}>
            {catogeries.length > 0 ? (
              catogeries.map((item, index) => (
                <Grid item xs={6} sm={4} md={4} lg={2} key={item.id}>
                  <ImageListItem
                    sx={{
                      width: "auto",
                      height: "100% !important",
                      margin: index < catogeries.length - 1 ? "0 0px 0px 0" : "0",
                      cursor: "pointer"
                    }}
                    onClick={() => handleClick(item)}
                  >
                    <img
                      src={item?.image?.src}
                      alt="product-img"
                      style={{
                        objectFit: "cover",
                        height: "100%",
                        width: "100%",
                      }}
                    />
                     {/* <ImageListItemBar title={item?.name} />   
                     //Commented out because the product names are already displayed in the background images. */}
                  </ImageListItem>
                </Grid>
              ))
            ) : (
              <Typography variant="body1" style={{ width: "100%", textAlign: "center", fontSize: "30px" }}>
                No data found
              </Typography>
            )}
          </Grid>
        )}
      </Container>
      <Box
        sx={{
          height: 550,
          mt: 5,
          backgroundImage: `url(${IMAGES.home_bg})`,
        }}
      >
        <Box
          sx={{
            display: "flex",
            backgroundColor: "rgba(0,0,0,0.5)",
            justifyContent: "center",
            alignItems: "center",
            height: 550,
          }}
        >
          <Container>
            <Typography
              style={{
                fontSize: 40,
                fontFamily: "PlayfairDisplay-Bold",
                color: COLORS.white,
              }}
            >
              Get{" "}
              <span
                style={{
                  fontSize: 50,
                  fontFamily: "PlayfairDisplay-Bold",
                  color: COLORS.secondary,
                }}
              >
                10%
              </span>{" "}
              Off your first <br /> Hair Scout purchase
            </Typography>
            <Typography
              style={{
                fontSize: 18,
                fontFamily: FONTS.subtitle,
                color: COLORS.white,
                lineHeight: 1.8,
                marginTop: 10,
              }}
            >
              Good for any one item
            </Typography>
            <Button
              className={classes.button}
              sx={{
                backgroundColor: COLORS.secondary,
                width: 180,
                height: 50,
                mt: 4,
                borderRadius: 0,
              }}
              onClick={handleClickProducts}
            >
              Shop Now
            </Button>
          </Container>
        </Box>
      </Box>
      <Box
        sx={{
          mt: 5,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: COLORS.other,
        }}

      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Container
            sx={{
              padding: "30px 15px",
            }}
          >
            <Grid container spacing={3}>
              <Grid item sm={12} md={4} lg={6}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <Box
                    style={{
                      width: "100%",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: 18,
                        fontFamily: FONTS.subtitle,
                        color: COLORS.black,
                      }}
                    >
                      Why People Choose Us?
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 50,
                        fontFamily: "PlayfairDisplay-Bold",
                        color: COLORS.black,
                      }}
                    >
                      Go ahead, Try One!
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 18,
                        fontFamily: FONTS.subtitle,
                        color: COLORS.grey,
                        lineHeight: 1.8,
                        marginTop: 10,
                      }}

                    >
                      We are always willing to make our customers happy and are
                      <br />
                      ready to support your most bold and creative ideas in
                      <br />
                      hairstyle.
                    </Typography>
                    <Button
                      className={classes.button}
                      sx={{
                        backgroundColor: COLORS.secondary,
                        width: 180,
                        height: 50,
                        mt: 4,
                        borderRadius: 0,
                      }}
                      onClick={handleClickProducts}
                    >
                      Shop Now
                    </Button>
                  </Box>
                </Box>
              </Grid>
              <Grid item sm={12} md={8} lg={6}>
                <div
                  style={{ display: "flex", width: "100%", margin: "10px 0px" }}
                >
                  <Container>
                    <AutoAwesomeIcon
                      sx={{
                        backgroundColor: COLORS.secondary,
                        height: 60,
                        width: 60,
                        p: 2,
                        borderRadius: 30,
                        mb: 2,
                      }}
                    />
                    <Typography
                      sx={{
                        fontFamily: FONTS.subtitle2,
                        fontSize: 22,
                        padding: "4px  0x",
                      }}
                    >
                      Large selection of vetted hair brands
                    </Typography>
                  </Container>
                  <Container>
                    <EnergySavingsLeafIcon
                      sx={{
                        backgroundColor: COLORS.secondary,
                        height: 60,
                        width: 60,
                        p: 2,
                        borderRadius: 30,
                        mb: 2,
                      }}
                    />
                    <Typography
                      sx={{
                        fontFamily: FONTS.subtitle2,
                        fontSize: 22,
                        padding: "10px 10x",
                      }}
                    >
                      In few minutes you can wear them to a party, for a lunch
                      date, for cosplay and to anywhere!
                    </Typography>
                    {/* <Typography>
                    In few minutes you can wear them to a party, for a lunch
                    date, for cosplay and to anywhere!
                  </Typography> */}
                  </Container>
                </div>
                <div style={{ display: "flex", width: "100%" }}>
                  <Container sx={{ pb: 2 }}>
                    <BubbleChartIcon
                      sx={{
                        backgroundColor: COLORS.secondary,
                        height: 60,
                        width: 60,
                        p: 2,
                        borderRadius: 30,
                        mb: 2,
                      }}
                    />
                    <Typography
                      sx={{
                        fontFamily: FONTS.subtitle2,
                        fontSize: 22,
                        padding: "4px  0x",
                      }}
                    >
                      Ability to chat with other shoppers before you purchase a
                      product
                    </Typography>
                  </Container>
                  <Container sx={{ pb: 2 }}>
                    <FavoriteIcon
                      sx={{
                        backgroundColor: COLORS.secondary,
                        height: 60,
                        width: 60,
                        p: 2,
                        borderRadius: 30,
                        mb: 2,
                      }}
                    />
                    <Typography
                      sx={{
                        fontFamily: FONTS.subtitle2,
                        fontSize: 22,
                        mt: 2,
                        padding: "4px  0x",
                      }}
                    >
                      Large selection of vetted hair brands
                    </Typography>
                    {/* <Typography>
                    In few minutes you can wear them to a party, for a lunch
                    date, for cosplay and to anywhere!
                  </Typography> */}
                  </Container>
                </div>
              </Grid>
            </Grid>
          </Container>
        </Box>
        {/* <Container
        disableGutters
        sx={{
          mb: 8,
          mt: 8,
          alignSelf: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{ alignSelf: "center", backgroundColor: "#fff" }}
        >
          <Grid item xs={12} sm={12} md={4} sx={{ backgroundColor: "#fff" }}>
            <Stack
              direction={{ xs: "row", sm: "row" }}
              sx={{
                alignItems: "center",
                boxShadow: 5,
                p: 2,
                backgroundColor: "#fff",
              }}
            >
              <CableOutlinedIcon
                sx={{ fontSize: 55, color: COLORS.black, mr: 3 }}
              />

              <Typography
                style={{
                  fontFamily: "PTSans-Regular",
                  color: COLORS.grey,
                  fontSize: 15,
                }}
              >
                <Typography
                  className=""
                  variant="h5"
                  style={{
                    fontFamily: "PlayfairDisplay-Bold",
                    color: COLORS.black,
                    fontSize: 20,
                  }}
                >
                  Access
                </Typography>
                Connecting shoppers with their perfect brands, and brands with
                their perfect customers
              </Typography>
            </Stack>
          </Grid>
          <Grid item sx={12} sm={12} md={4}>
            <Stack
              direction={{ xs: "row", sm: "row" }}
              sx={{
                alignItems: "center",
                boxShadow: 5,
                p: 2,
              }}
            >
              <GppGoodOutlinedIcon
                sx={{ fontSize: 55, color: COLORS.black, mr: 3 }}
              />
              <Typography
                style={{
                  fontFamily: "PTSans-Regular",
                  color: COLORS.grey,
                  fontSize: 15,
                }}
              >
                <Typography
                  variant="h5"
                  style={{
                    fontFamily: "PlayfairDisplay-Bold",
                    color: COLORS.black,
                    fontSize: 20,
                  }}
                >
                  Trust
                </Typography>
                We protect shoppers and brands from fraud so that you can shop
                and sell with confidence
              </Typography>
            </Stack>
          </Grid>
          <Grid item sx={12} sm={12} md={4}>
            <Stack
              direction={{ xs: "row", sm: "row" }}
              sx={{
                alignItems: "center",
                boxShadow: 5,
                p: 2,
                backgroundColor: COLORS.white,
              }}
            >
              <ShoppingCartOutlinedIcon
                sx={{ fontSize: 55, color: COLORS.black, mr: 3 }}
              />
              <Typography
                style={{
                  fontFamily: "PTSans-Regular",
                  color: COLORS.grey,
                  fontSize: 15,
                }}
              >
                <Typography
                  variant="h5"
                  style={{
                    fontFamily: "PlayfairDisplay-Bold",
                    color: COLORS.black,
                    fontSize: 20,
                  }}
                >
                  Ease
                </Typography>
                Life should be easy. We make the shopping and selling process
                simple and hassle-free for our users
              </Typography>
            </Stack>
          </Grid>
          {/* <Grid item sx={12} sm={12} md={3}>
            <Stack
              direction={{ xs: "row", sm: "row" }}
              sx={{
                alignItems: "center",
                backgroundColor: "#f5f5f5",
                padding: 2,   
                borderRadius: 2,
              }}
            >
              <PeopleAltOutlinedIcon
                sx={{ fontSize: 55, color: COLORS.black, mr: 2 }}
              />
              <Typography
                style={{
                  fontFamily: "PTSans-Regular",
                  color: COLORS.grey,
                  fontSize: 14,
                }}
              >
                <Typography
                  variant="h5"
                  style={{
                    fontFamily: "PTSans-Bold",
                    color: COLORS.black,
                    fontSize: 16,
                  }}
                >
                  Chat Facility
                </Typography>
                Chat directly with Scouts, and get the latest scoop on trends
              </Typography>
            </Stack>
          </Grid> 
        </Grid>
      </Container> */}
      </Box>
      <Footer />
    </React.Fragment>
  );
};

export default connect()(Home);
