import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
// material
import {
  Stack,
  TextField,
  Container,
  Link,
  Typography,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { BASE_URL } from "../../../apis";

// ----------------------------------------------------------------------

function RegisterFormCustomer({ setRegistrationSuccess }) {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailHelperText, setEmailHelperText] = useState("");
  const [registrationSuccess, setLocalRegistrationSuccess] = useState(false);

  const handleEmailChange = (event) => {
    const value = event.target.value;
    setEmail(value);

    // Simple email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (value === "" || emailRegex.test(value)) {
      setEmailError(false);
      setEmailHelperText("");
    } else {
      setEmailError(true);
      setEmailHelperText("Please use the correct format for email");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!firstName || !lastName || !email || emailError) {
      toast.error("Please fill in all required fields correctly.");
      return;
    }

    setLoading(true);

    const data = {
      first_name: firstName,
      last_name: lastName,
      email,
      registration_type: "customer",
    };

    try {
      const response = await fetch(`${BASE_URL.baseUrl}common/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
  
      const text = await response.text(); 
      setLoading(false);
  
      let result;
      try {
        result = JSON.parse(text);
      } catch (err) {
        toast.error(text || "An error occurred. Please try again.");
        return;
      }
  
      if (response.ok) {
        toast.success("Registration successful! Activation link sent to your email.");
        setLocalRegistrationSuccess(true);
        setRegistrationSuccess(true);
      } else {
        toast.error(result.message || "Registration failed");
      }
    } catch (error) {
      setLoading(false);
  
      // Check if error contains a message and display it, otherwise display a default message
      const errorMessage = error?.message || "An error occurred. Please try again.";
      toast.error(errorMessage);
    }
  };
  

  return (
    <Container>
      {registrationSuccess ? (
        <>
          <Typography
            variant="h5"
            sx={{
              textAlign: "center",
              fontFamily: "PlayfairDisplay-Bold",
              fontSize: 32,
              mb: 3,
              color: "text.primary",
            }}
          >
            Thanks for signing up
          </Typography>
          <Typography
            variant="body1"
            sx={{
              textAlign: "center",
              fontFamily: "PTSans-Regular",
              fontSize: 18,
              color: "text.secondary",
            }}
          >
            Check your inbox and junk mail to complete your registration
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Button
              variant="text"
              onClick={() => navigate("/login")}
              sx={{ fontSize: 16, textTransform: "none" }}
            >
              Sign In
            </Button>
          </div>
        </>
      ) : (
        <form onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
              <TextField
                fullWidth
                required
                label="First name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
              <TextField
                fullWidth
                required
                label="Last name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </Stack>

            <TextField
              fullWidth
              required
              autoComplete="username"
              type="email"
              label="Email address"
              value={email}
              onChange={handleEmailChange}
              error={emailError}
              helperText={emailHelperText}
            />

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={loading}
            >
              Register
            </LoadingButton>

            <Typography
              variant="body2"
              sx={{
                mt: { md: 2 },
                textAlign: "left",
                fontFamily: "PTSans-Regular",
                fontSize: 16,
                color: "text.secondary",
              }}
            >
              By signing up, you agree that you’ve read and accepted our{" "}
              <Link
                component="a"
                href="/terms-conditions"
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  fontWeight: "bold",
                  cursor: "pointer",
                  color: "text.secondary",
                  "&:hover": {
                    color: "text.secondary",
                  },
                  "&:active": {
                    color: "text.secondary",
                  },
                  "&:focus": {
                    color: "text.secondary",
                  },
                }}
              >
                Terms and Conditions.
              </Link>

              {" "}
              Please see our{" "}
              <Link
                component="a"
                href="/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  fontWeight: "bold",
                  cursor: "pointer",
                  color: "text.secondary",
                  "&:hover": {
                    color: "text.secondary",
                  },
                  "&:active": {
                    color: "text.secondary",
                  },
                  "&:focus": {
                    color: "text.secondary",
                  },
                }}
              >
                Privacy Policy
              </Link>
              {" "}
              for how information on how we process our data.
            </Typography>

            <Typography
              variant="body2"
              sx={{
                mt: { md: 2 },
                textAlign: "center",
                fontFamily: "PTSans-Regular",
                fontSize: 16,
                color: "text.secondary",
              }}
            >
              Already have an account? &nbsp;
              <Button
                variant="text"
                onClick={() => navigate("/login")}
                sx={{ fontSize: 16, textTransform: "none" }}
              >
                Sign In
              </Button>
            </Typography>
          </Stack>
        </form>
      )}
      <ToastContainer />
    </Container>
  );
}

export default RegisterFormCustomer;
