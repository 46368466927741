import React, { useState, useEffect } from "react";
import { Container, Grid, Card, CardHeader, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate, useLocation } from "react-router-dom";
import { NavStrip } from "../../../components/website/Navbar";
import Footer from "../../../components/website/Footer";
import { BASE_URL } from "../../../apis";
import { handleGetApi } from "../../../services";
import { toast } from "react-toastify";
import LoadingModal from "../../../components/modals/LoadingModal";

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

function CategoriesList() {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    getCategories();
  }, []); // Fetch data on component mount

  const getCategories = async () => {
    setLoading(true);
    try {
      const response = await handleGetApi(
        `${BASE_URL.baseUrl}categories?Limit=100&Page=1&PublishedStatus=true&ParentCategoryId=0`
      );
      setCategories(response.data.categories);
      setLoading(false);
    } catch (err) {
      toast.error("Something Went Wrong");
      setLoading(false);
    }
  };

  const handleClick = (item) => {
    navigate("/sub-categories-list", { state: item });
  };

  return (
    <>
      <NavStrip />
      <RootStyle>
        <Container maxWidth="lg">
          <Typography variant="h4" sx={{ mb: 4, mt: 5 }}>
            Categories
          </Typography>
          {loading ? (
            <LoadingModal open={loading} />
          ) : (
            <Grid container spacing={3}>
              {categories
                .filter((category) => category?.image?.src)
                .map((category, index) => (
                  <Grid
                    item
                    key={index}
                    xs={12} // Full width on mobile screens
                    sm={4}  // Original width on small screens
                    md={4.5}  // Original width on medium screens
                    style={{ margin: "10px auto", cursor: "pointer" }}
                  >
                    <Card sx={{ height: "100%" }} onClick={() => handleClick(category)}>
                      <Card
                        style={{
                          backgroundImage: `url(${category?.image?.src})`,
                          backgroundSize: "cover",
                          position: "relative",
                          minHeight: "400px",
                        }}
                      ></Card>

                      <CardHeader
                        title={category.name}
                        titleTypographyProps={{
                          sx: { fontSize: 15 },
                        }}
                        sx={{ mb: 2 }}
                      />
                    </Card>
                  </Grid>
                ))}
            </Grid>
          )}
        </Container>
      </RootStyle>
      <Footer />
    </>
  );
}

export default CategoriesList;
