import {
    Avatar,
    Box,
    Button,
    Container,
    Stack,
    Switch,
    Tab,
    Tabs,
    Typography,
    TextField,
    IconButton,
    Grid
  } from "@mui/material";
  import { useEffect, useState } from "react";
  import moment from "moment";
  import { useLocation, useNavigate } from "react-router-dom";
  import LoadingModal from "../../../components/modals/LoadingModal";
  import Page from "../../../components/Page";
  import { COLORS, ICONS, IMAGES } from "../../../constants";
  import StarIcon from "@mui/icons-material/Star";
  import StoreMallDirectoryIcon from "@mui/icons-material/StoreMallDirectory";
  import OrderCard from "../../../components/OrderCard";
  import { toast } from "react-toastify";
  import { connect } from "react-redux";
  import { getOrderDetails, getVendorOrders } from "../../../redux/actions";
  import { NoProduct } from "../../../components";
  import Order from "../../../components/Order";
  import ordersHistory from "../../vendor/orders/ordersHistory";
  import CustomerOrder from "../../../components/CustomerOrder";
  import SearchIcon from "@mui/icons-material/Search";
  import FilterListIcon from "@mui/icons-material/FilterList";
  import ArrowBackIcon from "@mui/icons-material/ArrowBack";

  function OrderDetail(props) {
    const navigate = useNavigate();
    const [value, setValue] = useState(0);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [searchQuery, setSearchQuery] = useState("");
    const [filterOptions, setFilterOptions] = useState({});
    const [filteredOrders, setFilteredOrders] = useState([]);
    const { state } = useLocation();
    const { orderId } = state;
    const [order, setOrder] = useState(null); // Initialize as null
    const { orderLoading } = props.vendorOrders;
  
    const getOrderById = async () => {
      const { accessToken } = props.authentication;
  
      try {
        const response = await getOrderDetails(orderId, accessToken);
        if (response?.order_items && response.order_items.length > 0) {
          // console.log(response.order_items[0].product, 'product details');
        }
        setOrder(response);
      } catch (error) {
        toast.error(error.message);
      }
    };
  
    useEffect(() => {
      getOrderById();
    }, []);
  

    return (
      <Page title="Dashboard | Orders History">
         <Container maxWidth="xl">
         <IconButton onClick={() => navigate("/customer-history")} sx={{ mt:2 }}>
            <ArrowBackIcon />
          </IconButton>
         <Typography variant="h4" sx={{ fontFamily: "PlayfairDisplay-Bold", mt:5}}>
          Order: {orderId}
        </Typography>
          <Box sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={7}>
                <Stack sx={{ boxShadow: 4, p: 2, borderRadius: 1 }}>
                  <Typography
                    variant="h6"
                    sx={{ fontFamily: "PTSans-Bold", color: "text.secondary" }}
                  >
                    Items Summary
                  </Typography>
                  {order?.order_items?.map((item, index) => (
                    <Stack
                      key={index}
                      direction="row"
                      sx={{
                        width: "100%",
                        my: 2,
                        //   backgroundColor: COLORS.secondary,
                      }}
                    >
                      {item?.product?.images?.[0]?.src && (
                        <img
                          src={item.product.images[0].src}
                          style={{ height: 100, width: 100, borderRadius: 10 }}
                        />
                      )}
                      <Box
                        sx={{
                          mx: 3,
                          justifyContent: "center",
                          alignItems: "center",
                          width: "50%",
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "PTSans-Bold",
                            fontSize: 18,
                            color: COLORS.secondary,
                            mb: 1,
                          }}
                        >
                          {item?.product?.name}
                        </Typography>
                        <Stack direction={{ xs: "row", sm: "row" }}>
                          <Typography
                            sx={{
                              fontFamily: "PTSans-Bold",
                              fontSize: 13,
                              width: 100,
                            }}
                          >
                            Color
                          </Typography>
                          <Typography
                            sx={{ fontFamily: "PTSans-Regular", fontSize: 13 }}
                          >
                            1B
                          </Typography>
                        </Stack>
                        <Stack direction={{ xs: "row", sm: "row" }}>
                          <Typography
                            sx={{
                              fontFamily: "PTSans-Bold",
                              fontSize: 13,
                              width: 100,
                            }}
                          >
                            Length
                          </Typography>
                          <Typography
                            sx={{ fontFamily: "PTSans-Regular", fontSize: 13 }}
                          >
                            8 Inches
                          </Typography>
                        </Stack>
                        <Stack direction={{ xs: "row", sm: "row" }}>
                          <Typography
                            sx={{
                              fontFamily: "PTSans-Bold",
                              fontSize: 13,
                              width: 100,
                            }}
                          >
                            Quantity
                          </Typography>
                          <Typography
                            sx={{ fontFamily: "PTSans-Regular", fontSize: 13 }}
                          >
                            {item?.quantity}
                          </Typography>
                        </Stack>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: COLORS.backgroundDark,
                          height: 100,
                          width: 100,
                          borderRadius: 1,
                        }}
                      >
                        <Box>
                          <Typography
                            sx={{
                              fontFamily: "PTSans-Bold",
                              fontSize: 13,
                              width: 100,
                              color: COLORS.grey,
                              textAlign: "center",
                            }}
                          >
                            Sub Total
                          </Typography>
                          <Typography
                            sx={{
                              fontFamily: "PTSans-Bold",
                              fontSize: 18,
                              width: 100,
                              color: COLORS.secondary,
                              textAlign: "center",
                            }}
                          >
                            ${item?.price_incl_tax?.toFixed(2)}
                          </Typography>
                        </Box>
                      </Box>
                    </Stack>
                  ))}
                </Stack>
              </Grid>
              <Grid item xs={12} sm={12} md={5}>
                <Stack sx={{ boxShadow: 4, p: 2, borderRadius: 1 }}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: "PTSans-Bold",
                      color: "text.secondary",
                      mb: 1,
                    }}
                  >
                    Order Summary
                  </Typography>
                  <Stack
                    direction={{ xs: "row", sm: "row" }}
                    sx={{ justifyContent: "space-between" }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "PTSans-Bold",
                        fontSize: 14,
                        width: 120,
                        color: COLORS.grey,
                      }}
                    >
                      Order Created
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "PTSans-Bold",
                        fontSize: 13,
                        width: 150,
                        color: COLORS.secondary,
                      }}
                    >
                      {moment(order?.created_on_utc).format("LL")}
                    </Typography>
                  </Stack>
                  <Stack
                    direction={{ xs: "row", sm: "row" }}
                    sx={{ justifyContent: "space-between" }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "PTSans-Bold",
                        fontSize: 14,
                        width: 120,
                        color: COLORS.grey,
                      }}
                    >
                      Order Time
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "PTSans-Bold",
                        fontSize: 13,
                        width: 150,
                        color: COLORS.secondary,
                      }}
                    >
                      {moment(order?.created_on_utc).format("HH:mm:ss")}
                    </Typography>
                  </Stack>
                  <Stack
                    direction={{ xs: "row", sm: "row" }}
                    sx={{ justifyContent: "space-between" }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "PTSans-Bold",
                        fontSize: 14,
                        width: 120,
                        color: COLORS.grey,
                      }}
                    >
                      Order Status
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "PTSans-Bold",
                        fontSize: 13,
                        width: 150,
                        color: COLORS.secondary,
                      }}
                    >
                      {order?.order_status}
                    </Typography>
                  </Stack>
                  <Stack
                    direction={{ xs: "row", sm: "row" }}
                    sx={{ justifyContent: "space-between" }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "PTSans-Bold",
                        fontSize: 14,
                        width: 120,
                        color: COLORS.grey,
                      }}
                    >
                      Delivery Charges
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "PTSans-Bold",
                        fontSize: 13,
                        width: 150,
                        color: COLORS.secondary,
                      }}
                    >
                      ${order?.order_tax?.toFixed(2)}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack sx={{ boxShadow: 4, p: 2, borderRadius: 1, mt: 2 }}>
                  <Stack
                    direction={{ xs: "row", sm: "row" }}
                    sx={{ justifyContent: "space-between" }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "PTSans-Bold",
                        fontSize: 14,
                        width: 120,
                        color: COLORS.grey,
                      }}
                    >
                      Order Total
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "PTSans-Bold",
                        fontSize: 13,
                        width: 150,
                        color: COLORS.secondary,
                      }}
                    >
                      ${order?.order_total?.toFixed(2)}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack sx={{ boxShadow: 4, p: 2, borderRadius: 1, mt: 2 }}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: "PTSans-Bold",
                      color: "text.secondary",
                      mb: 1,
                    }}
                  >
                    Delivery Details
                  </Typography>
                  <Stack
                    direction={{ xs: "row", sm: "row" }}
                    sx={{ justifyContent: "space-between" }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "PTSans-Bold",
                        fontSize: 14,
                        width: 120,
                        color: COLORS.grey,
                      }}
                    >
                      Address 1
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "PTSans-Bold",
                        fontSize: 13,
                        width: 150,
                        color: COLORS.secondary,
                      }}
                    >
                      {order?.shipping_address?.address1}
                    </Typography>
                  </Stack>
                  <Stack
                  direction={{ xs: "row", sm: "row" }}
                  sx={{ justifyContent: "space-between" }}
                >
                  <Typography
                    sx={{
                      fontFamily: "PTSans-Bold",
                      fontSize: 14,
                      width: 120,
                      color: COLORS.grey,
                    }}
                  >
                    Address 2
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "PTSans-Bold",
                      fontSize: 13,
                      width: 150,
                      color: COLORS.secondary,
                    }}
                  >
                    {order?.shipping_address?.address2}
                  </Typography>
                </Stack>
                <Stack
                  direction={{ xs: "row", sm: "row" }}
                  sx={{ justifyContent: "space-between" }}
                >
                  <Typography
                    sx={{
                      fontFamily: "PTSans-Bold",
                      fontSize: 14,
                      width: 120,
                      color: COLORS.grey,
                    }}
                  >
                    State
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "PTSans-Bold",
                      fontSize: 13,
                      width: 150,
                      color: COLORS.secondary,
                    }}
                  >
                    {order?.shipping_address?.province}
                  </Typography>
                </Stack>
                <Stack
                  direction={{ xs: "row", sm: "row" }}
                  sx={{ justifyContent: "space-between" }}
                >
                  <Typography
                    sx={{
                      fontFamily: "PTSans-Bold",
                      fontSize: 14,
                      width: 120,
                      color: COLORS.grey,
                    }}
                  >
                    State Id
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "PTSans-Bold",
                      fontSize: 13,
                      width: 150,
                      color: COLORS.secondary,
                    }}
                  >
                    {order?.shipping_address?.state_province_id}
                  </Typography>
                </Stack>
                  <Stack
                    direction={{ xs: "row", sm: "row" }}
                    sx={{ justifyContent: "space-between" }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "PTSans-Bold",
                        fontSize: 14,
                        width: 120,
                        color: COLORS.grey,
                      }}
                    >
                      Zip Code
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "PTSans-Bold",
                        fontSize: 13,
                        width: 150,
                        color: COLORS.secondary,
                      }}
                    >
                      {order?.shipping_address?.zip_postal_code}
                    </Typography>
                  </Stack>
                  <Stack
                    direction={{ xs: "row", sm: "row" }}
                    sx={{ justifyContent: "space-between" }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "PTSans-Bold",
                        fontSize: 14,
                        width: 120,
                        color: COLORS.grey,
                      }}
                    >
                      Shipping Method
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "PTSans-Bold",
                        fontSize: 13,
                        width: 150,
                        color: COLORS.secondary,
                      }}
                    >
                      {order?.shipping_method}
                    </Typography>
                  </Stack>
                  <Stack
                    direction={{ xs: "row", sm: "row" }}
                    sx={{ justifyContent: "space-between" }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "PTSans-Bold",
                        fontSize: 14,
                        width: 120,
                        color: COLORS.grey,
                      }}
                    >
                      Shipping Status
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "PTSans-Bold",
                        fontSize: 13,
                        width: 150,
                        color: COLORS.secondary,
                      }}
                    >
                      {order?.shipping_status}
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </Box>
          <LoadingModal open={orderLoading} />
          {/* <AddInventoryModal open={open} handleClose={handleCloseModal} /> */}
        </Container>
      </Page>
    );
  }
  
  const mapStateToProps = (state) => ({
    authentication: state.authentication,
    common: state.common,
    vendorOrders: state.vendorOrders,
  });
  
  const mapDispatchToProps = (dispatch) => ({
    getOrderDetails: (orderId, token) => dispatch(getOrderDetails(orderId, token)),
  });
  export default connect(mapStateToProps, mapDispatchToProps)(OrderDetail);
  